
.graph-tooltip {
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
}

.graph-tooltip-heading {
    font-size: 14px;
    color: #666;
}

.graph-tooltip-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* border: 1px solid black; */
}

.graph-tooltip-label {
    flex: 1;
    padding-right: 5px;
}

.graph-tooltip-value {
    /* flex: 1; */
}

.m-r-4 {
    margin-right: 6px;
} 