.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow-y: auto;
}

.App-header {
  display: flex;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.App-header-r {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.refresh-button {
  display: flex;
  align-items: center;
}

.graph-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table-symbol {
  cursor: pointer
}

.table-container-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;

  table {
    flex: 1
  }
}